import React from 'react';
import Slide from 'react-reveal/Slide';

import imgTeam from '../images/img-velo.svg';

const HomeSectionThree = () => {
	return (
		<section className="md: pt-6">
			<Slide top>
				<h1 className="text-2xl sm:text-3xl md:text-5xl text-center pt-10 pb-6 text-eeb-gray">
					Une église <span className="text-eeb-red">pertinente</span>
				</h1>
			</Slide>
			<div className="flex flex-col lg:flex-row justify-center items-center align-middle pt-16 md:pt-1 px-2 lg:px-24 sm:px-12 md:py-10">
				<Slide left>
					<div className="text-center md:pr-10">
						<img
							src={imgTeam}
							alt="eglise evangélique baptiste de Rouen famille qui lit la bible"
							title="eeb eglise évangélique baptiste de Rouen"
							width="3500"
						/>
					</div>
				</Slide>
				<div className="lg:px-12">
					<Slide right>
						<p className="pb-6 text-gray-600 text-lg font-bold italic">
							Dieu peut-il te parler d’une manière accessible, compréhensible et non ennuyeuse ?
						</p>
						<p className="font-light text-gray-900">
							Notre communauté propose un{' '}
							<span className="text-eeb-red font-semibold">culte accessible et moderne</span>, convivial
							et chaleureux. L' <span className="text-eeb-red font-semibold">Evangile</span> est un{' '}
							<span className="text-eeb-red font-semibold">
								message d'espérance immuable que l'église communique de façon actuelle
							</span>. Notre but est de rester pertinent, à l'écoute des besoins de chaque personne. Nous
							saisissons les évènement sportifs, artistiques, culturels pour être pleinement intégrés au
							milieu de la ville avec nos valeurs. Un{' '}
							<span className="text-eeb-red font-semibold">parcours alpha</span> permet d’explorer le
							christianisme dans un cadre convivial. Les jeunes animent une rencontre{' '}
							<span className="text-eeb-red font-semibold">Canap’ & Foi</span> ouverte à tous. Des
							rencontres sont proposées dans les maisons en semaine.
						</p>
					</Slide>
				</div>
			</div>
		</section>
	);
};

export default HomeSectionThree;
