import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import HomeSectionOne from '../components/HomeSectionOne';
import HomeSectionTwo from '../components/HomeSectionTwo';
import HomeSectionThree from '../components/HomeSectionThree';
import HomeSectionHorraire from '../components/HomeSectionHorraire';
// import HomeNews from '../components/HomeNews';
import HomeAbout from '../components/HomeAbout';

const IndexPage = () => (
	<Layout>
		<SEO
			keywords={[
				`église évangélique baptiste`,
				`eglise evangelique baptiste`,
				`eglise baptiste`,
				`eglise baptiste à rouen`,
				`eglise baptiste rouen`,
				`eglise evangélique rouen`,
				`église évangélique à rouen`,
				`église à rouen`,
				`église centre ville rouen`,
				`eeb rouen`,
				`eeb`,
				`église évangélique baptiste Rouen`,
				`eeb-rouen`,
				`Eglise évangélique baptiste de Rouen`
			]}
			title="Eglise Évangélique Baptiste de Rouen"
			description="Eglise Évangélique Baptiste de Rouen situé au 50 Rue Albert Camus, 76100 Rouen"
		/>
		{/* <HomeNews/> */}
		<HomeSectionOne />
		<HomeSectionTwo />
		<HomeSectionThree />
		<HomeAbout />
		<HomeSectionHorraire />
	</Layout>
);

export default IndexPage;
