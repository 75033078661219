import React from 'react';
import Slide from 'react-reveal/Slide';
import imgTeam from '../images/img-team.svg';

const HomeSectionOne = () => {
	return (
		<section className="px-2" id="about">
			<h1 className="text-2xl sm:text-3xl md:text-5xl text-center pt-12 pb-8 text-eeb-gray">
				Une église <span className="text-eeb-green-dark">familiale</span>
			</h1>
			<div className="flex flex-col lg:flex-row justify-center items-center align-middle sm:px-12 lg:px-24 md:py-4">
				<Slide left>
					<div className="text-center lg:pr-10">
						<img
							src={imgTeam}
							alt="eglise evangélique baptiste de Rouen  famille qui part a l'eglise"
							title="eeb eglise évangélique baptiste de Rouen"
							width="3500"
						/>
					</div>
				</Slide>
				<div className="lg:px-12">
					<Slide right>
						<p className="pt-6 md:pt-0 pb-6 text-gray-600 text-lg md:text-2xl font-bold italic">
							Y a-t-il une place pour toi dans la grande famille de Dieu ?
						</p>
						<p className="font-light text-gray-900">
							Chacun est accueilli dans la liberté et le respect. Nous sommes une famille chaleureuse avec
							des personnes de toutes <span className="text-eeb-green-dark font-semibold">nations</span>,
							de toutes <span className="text-eeb-green-dark font-semibold">générations</span> et de
							toutes <span className="text-eeb-green-dark font-semibold">conditions</span>. Les personnes
							en situation de <span className="text-eeb-green-dark font-semibold">handicap</span> y
							trouvent leur place. Le culte commence et se termine autour d’un café pour faire
							connaissance. Les <span className="text-eeb-green-dark font-semibold">enfants</span> sont
							pris en charge lors du culte (hors vacances scolaires). Un{' '}
							<span className="text-eeb-green-dark font-semibold">repas convivial</span> est partagé tous
							les mois environ, ce qui nous permet de passer du temps ensemble. Les{' '}
							<span className="text-eeb-green-dark font-semibold">femmes</span> se rencontrent de façon
							régulière le samedi matin pour un temps de partage, de prière et d’étude de la Bible.
						</p>
					</Slide>
				</div>
			</div>
		</section>
	);
};

export default HomeSectionOne;
