import React from 'react';
import styled from 'styled-components';
import bg from '../images/bgHorraire.png';
import {useStaticQuery, graphql} from 'gatsby'

const Homesectionhorraire = () => {
	const data = useStaticQuery(graphql`
		{
			file(relativePath: { eq: "bgHorraire.png" }) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
		}
  `);
  // const image = data.childImageSharp.fluid
	return (
		<Div className=" text-gray-200 py-16  md:py-40 mt-12 sm:px-12">
			<div className=" px-2 md:container">
				<p className="text-2xl md:text-4xl pb-4 font-bold ">Réunion :</p>
				<p className="font-semibold md:text-2xl">
					Culte dominicale -{'>'} 10h30 <br />
				</p>
			</div>
		</Div>
	);
};

export default Homesectionhorraire;

const Div = styled.section`
	background-image: url(${bg});
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	/* background-color: red; */
`;
