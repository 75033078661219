import React from 'react';
import Slide from 'react-reveal/Slide';

import imgTeam from '../images/img-reading.svg';

const HomeSectionTwo = () => {
	return (
		<section className="flex flex-col lg:flex-row-reverse justify-center items-center align-middle pt-16 px-2 md:px-12">
			<Slide right>
				<div className="text-center md:pr-10">
					<img
						src={imgTeam}
						alt="eglise evangélique baptiste de Rouen famille qui part a l'eglise"
						title="eeb eglise évangélique baptiste de Rouen"
						width="2500"
					/>
				</div>
			</Slide>
			<div className="sm:px-12 ">
				<Slide top>
					<h1 className="text-2xl sm:text-3xl  md:text-5xl text-center pt-10 pb-6 text-eeb-gray">
						Une église <span className="text-eeb-orange">éclairante</span>
					</h1>
				</Slide>
				<Slide left>
					<p className="pb-6 text-gray-600 text-lg md:text-2xl font-bold italic">
						Dieu peut-il éclairer nos vies, réchauffer nos cœurs, nous donner une direction ?
					</p>
					<p className="font-light text-gray-900">
						Nous croyons que Sa Parole, <span className="text-eeb-orange font-semibold">la Bible</span>, est
						le premier moyen par lequel Dieu se révèle aux hommes et aux femmes et leur fait connaitre son
						amour. Son <span className="text-eeb-orange font-semibold">église</span>, est le lieu propice
						pour être éclairé afin de vivre une foi réelle. Sa Parole est donc enseignée chaque dimanche
						matin lors du <span className="text-eeb-orange font-semibold">culte</span> mais aussi au travers
						d’<span className="text-eeb-orange font-semibold">études</span>
						bibliques régulières.
					</p>
				</Slide>
			</div>
		</section>
	);
};

export default HomeSectionTwo;
