import React from 'react';
import Slide from 'react-reveal/Slide';

function HomeAbout() {
	return (
		<section className="md:py-6 px-2 md:container">
			<Slide top>
				<h1 className="text-2xl sm:text-3xl  md:text-5xl text-center pt-10 pb-6 text-eeb-gray">
					Qui sommes <span className="text-eeb-orange">nous ?</span>
				</h1>
			</Slide>
			<div className="">
				<p className="pb-4">
					<span className="text-eeb-red font-semibold">
						Notre communauté fait partie de la{' '}
						<a href="https://federation.feebf.com/" target="_blank" rel="noreferrer" className=" underline">
							FEEBF
						</a>
					</span>{' '}
					(Fédération des Eglises Evangélique Baptiste de France). La FEEBF a intégré la FPF (Fédération
					Protestante de France) puis plus récemment le{' '}
					<span className="text-eeb-red font-semibold">
						<a href="https://www.lecnef.org/page/170867-le-cnef" target="_blank" rel="noreferrer" className=" underline">
							CNEF
						</a>
					</span>{' '}
					(Conseil National des Evangélique de France).
				</p>
				<p className="pb-4">
					<span className="text-eeb-red font-semibold">
						L’église œuvre en collaboration étroite avec les églises de Rouen
					</span>{' '}
					et organise conjointement plusieurs évènements pour mieux rayonner ensemble : prière communes chaque
					année lors de la semaine universelle de prière, tournois de foot en 2019...
				</p>
				<p className="pb-4">
					<span className="text-eeb-red font-semibold">L’église fait partie du Réseau La Source </span>
					fondé il y a quelques années. Ce réseau rassemble des communautés naissantes à Lisieux, Caen, Le
					Neubourg, Honfleur, Saint Lo ainsi qu’un café associatif à Lisieux. Nous avons également de nombreux
					partenariats en France et à l’étranger.
				</p>
			</div>
		</section>
	);
}

export default HomeAbout;
